import React from "react"
import { graphql } from "gatsby"

import Layout from './../components/Layout';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { html, headings } = markdownRemark

  const toc = headings.filter((heading) => heading.depth < 4);
  const t = toc.reduce((acc, curr) => {
    if(curr.depth === 2){
        acc[curr.value] = [];
    }
    else{
        const keys = Object.keys(acc);
        acc[keys[keys.length - 1]].push(curr.value)
    }
    return acc;
  }, {});

  const get_id = (string) => '#' + string.toLowerCase().replace(/[()&]/g, '').replace(/[\s]/g, '-');

  return (
      <Layout>
        <div>
        <div
        id="api-doc-toc"
        >
            <ul style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'stretch',
                marginLeft: 'unset',
            }}>
                {
                    Object.keys(t).map((section, idx) => 
                        <li 
                            key={`section-${idx}`}
                            style={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'column',
                            }}
                        >
                            <a 
                                href={get_id(section)}
                                style={{
                                    flex: '1 1 auto',
                                    padding: '0.4rem'
                                }}
                            >
                                {section}
                            </a>
                            <ul style={{
                                width: '100%',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'stretch',
                            }}>
                                {
                                    t[section].map((subsection, idx) => 
                                        <li 
                                            key={`subsection-${idx}`}
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                            }}
                                        >
                                            <a 
                                                href={get_id(subsection)} 
                                                style={{
                                                    flex: '1 1 auto',
                                                    padding: '0.4rem'
                                                }}
                                            >
                                                {subsection}
                                            </a>
                                        </li>)
                                }
                            </ul>
                        </li>
                    )
                }
            </ul>
        </div>
        <div
            id="api-doc-content"
            dangerouslySetInnerHTML={{ __html: html }}
        />
        </div>
      </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      headings {
        depth
        value
      }
    }
  }
`